import { type ReactNode, useEffect, useState } from 'react'
import { cx } from '@/utils/strings'

import Spinner from '@/components/ui/spinner'

export interface FormProps {
  locale: any
  comments: any
  formName: string
  url: string
  email: string
  businessPurpose: string
}
interface Props {
  id: string
  name: string
  afterSubmit: (response) => void
  transformFormData?: (data: FormData) => FormData
  functionPath?: string
  headers?: Record<string, string>
  submitText?: string
  children: ReactNode
  hideButton?: boolean
}

export default function Form({
  id,
  name,
  afterSubmit,
  transformFormData,
  functionPath = '/api/forms',
  submitText = 'Submit',
  headers = { Accept: 'application/json' },
  children,
  hideButton,
  ...props
}: Props): JSX.Element {
  const [isInitializing, setIsInitializing] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [path, setPath] = useState('')
  const [search, setSearch] = useState()

  useEffect(() => {
    setSearch(new URLSearchParams(window.location).get('search'))
    setPath(window.location.pathname)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const params = new URLSearchParams(search)
      params.forEach((val, key) => {
        document.getElementsByName(key).forEach((el: HTMLInputElement) => {
          el.value = val
          el.dispatchEvent(new Event('change'))
        })
      })
    }, 100)

    setTimeout(() => {
      setIsInitializing(false)
    }, 3000)
  }, [])

  return (
    <form
      id={id}
      name={name}
      onSubmit={async (event) => {
        event.preventDefault()

        setIsSubmitting(true)
        setErrorMessages([])

        let formData = new FormData(event.target as HTMLFormElement)
        if (transformFormData) formData = transformFormData(formData)

        const body = headers?.['Content-Type']?.includes('json')
          ? JSON.stringify(Object.fromEntries(formData.entries()))
          : formData

        // timeout if there is no response after a period
        const controller = new AbortController()
        const { signal } = controller
        setTimeout(() => controller.abort(), 31000)

        const response = await fetch(functionPath, {
          method: 'POST',
          body,
          headers,
          signal,
        }).catch((err) => ({
          ok: false,
          statusText: err.message || 'Unknown error',
        }))

        if (response?.ok === false) setErrorMessages([response.statusText])
        else afterSubmit(response)
        setIsSubmitting(false)
      }}
      {...props}
    >
      <input type="hidden" name="formName" value={name} />
      <input type="hidden" name="url" value={path} />
      <input type="hidden" name="locale" value={typeof window !== 'undefined' ? navigator?.language : ''} />
      {children}
      {!isInitializing && typeof window !== 'undefined' && (
        <div className="w-full text-white osano-cm-widget">
          <input name="businessPurpose" type="text" placeholder="Business Purpose*" className="placeholder-[#8E90A6]" />
        </div>
      )}

      {errorMessages?.length > 0 && (
        <div className="p-4 mt-2 bg-red-600 text-white rounded-2xl text-center">
          <h4>Whoops, we encountered a problem.</h4>
          {errorMessages.map((err, i) => (
            <div key={`error-${i}`} role="alert" className="flex justify-center">
              <span className="font-bwi mr-2">&#xe94a;</span>
              {err}
            </div>
          ))}
        </div>
      )}
      <button
        className={cx(
          'relative bg-primaryBlue text-white mt-4 px-[20px] py-[8px] text-xl font-normal leading-normal rounded-full hover:bg-indigoBlue',
          isSubmitting && 'cursor-not-allowed opacity-40',
          hideButton ? 'hidden' : 'block'
        )}
        type="submit"
      >
        <div className={cx('hidden', isSubmitting && 'block')}>
          <Spinner size="1.25rem" color="white" borderWidth="2px" />
        </div>

        <span className={cx(isSubmitting && 'opacity-0')}>{submitText}</span>
      </button>
    </form>
  )
}
